.App {
  display: flex;
  flex-direction: column;
  position: relative;
  background: #18191f;
  font-size: calc(10px + 2vmin);
  align-items: stretch;
  justify-content: space-around;

  width: fit-content;

}

ul {
  position: relative;
  display: flex;
}

li {
  list-style: none;
}

label {
  position: relative;
}