@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #18191f;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Name_cont_figuras__3GTQc {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    position: relative;
    background: #18191f;
    color: #555;
    padding: 0;
    margin: 0; 
    font-family: 'Poppins', sans-serif;

}

input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 50px;
    width: 50px;
    z-index: 100;
  }

  input[type="checkbox"]:checked ~ div {
    box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.05),
      inset 4px 4px 6px rgba(0, 0, 0, 0.2);
    color: yellow;
    text-shadow: 0 0 15px yellow, 0 0 25px yellow;
    -webkit-animation: Name_glow__TjQtw 1.5s linear infinite;
            animation: Name_glow__TjQtw 1.5s linear infinite;
  }
  
  @-webkit-keyframes Name_glow__TjQtw {
    0% {
      -webkit-filter: hue-rotate(0deg);
              filter: hue-rotate(0deg);
    }
    100% {
      -webkit-filter: hue-rotate(360deg);
              filter: hue-rotate(360deg);
    }
  }
  
  @keyframes Name_glow__TjQtw {
    0% {
      -webkit-filter: hue-rotate(0deg);
              filter: hue-rotate(0deg);
    }
    100% {
      -webkit-filter: hue-rotate(360deg);
              filter: hue-rotate(360deg);
    }
  }

  .Name_div_letras__1qoqP {
    position: relative;
    height: 50px;
    width: 50px;
    background: #18191f;
    color: #555;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    cursor: pointer;
    margin: 0 4px;
    border-radius: 20px;
    box-shadow: -1px -1px 4px rgba(255, 255, 255, 0.05),
      4px 4px 6px rgba(0, 0, 0, 0.2),
      inset -1px -1px 4px rgba(255, 255, 255, 0.05),
      inset 1px 1px 1px rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: 616px) {
    .Name_div_letras__1qoqP {
        height: 25px;
        width: 25px;
        font-size: 16px;
    }

    input[type="checkbox"] {
        height: 25px;
        width: 25px;
      }
  }

.Links_cont_links__1XORp {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;

    position: relative;
    background: #18191f;
    color: #555;
    justify-content: center;
    align-items: center;

    padding: 0;
    margin: 0; 
    font-family: 'Poppins', sans-serif;
}
    
.Links_icon__zV1Z0 {
    -webkit-filter: invert(40%) sepia(16%) saturate(127%) hue-rotate(288deg) brightness(85%) contrast(100%);
            filter: invert(40%) sepia(16%) saturate(127%) hue-rotate(288deg) brightness(85%) contrast(100%);
    width: 8%;
    padding: 0 1.5%;
    margin: 30px;

    width: 40px;
    height: 40px; 
    margin-left: 10px;
    margin-right: 10px;
  }
.Links_blue__I3wNk {
  color: rgb(250, 158, 170);
  }
  a:hover, a:active {
    -webkit-filter: invert(60%) sepia(76%) saturate(219%) hue-rotate(194deg) brightness(97%) contrast(103%);
            filter: invert(60%) sepia(76%) saturate(219%) hue-rotate(194deg) brightness(97%) contrast(103%);
  }

  .Links_img_links__1WdXv {
    width: 40px;
    height: 40px; 
    margin-left: 10px;
    margin-right: 10px;
  }

  @media screen and (max-width: 616px) {
    .Links_icon__zV1Z0 {
      width: 20px;
      height: 20px; 
      margin-left: 5px;
      margin-right: 5px;
    }
  }
.App {
  display: flex;
  flex-direction: column;
  position: relative;
  background: #18191f;
  font-size: calc(10px + 2vmin);
  align-items: stretch;
  justify-content: space-around;

  width: -webkit-fit-content;

  width: -moz-fit-content;

  width: fit-content;

}

ul {
  position: relative;
  display: flex;
}

li {
  list-style: none;
}

label {
  position: relative;
}
