@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');

.cont_figuras {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    position: relative;
    background: #18191f;
    color: #555;
    padding: 0;
    margin: 0; 
    font-family: 'Poppins', sans-serif;

}

input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 50px;
    width: 50px;
    z-index: 100;
  }

  input[type="checkbox"]:checked ~ div {
    box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.05),
      inset 4px 4px 6px rgba(0, 0, 0, 0.2);
    color: yellow;
    text-shadow: 0 0 15px yellow, 0 0 25px yellow;
    animation: glow 1.5s linear infinite;
  }
  
  @keyframes glow {
    0% {
      filter: hue-rotate(0deg);
    }
    100% {
      filter: hue-rotate(360deg);
    }
  }

  .div_letras {
    position: relative;
    height: 50px;
    width: 50px;
    background: #18191f;
    color: #555;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    cursor: pointer;
    margin: 0 4px;
    border-radius: 20px;
    box-shadow: -1px -1px 4px rgba(255, 255, 255, 0.05),
      4px 4px 6px rgba(0, 0, 0, 0.2),
      inset -1px -1px 4px rgba(255, 255, 255, 0.05),
      inset 1px 1px 1px rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: 616px) {
    .div_letras {
        height: 25px;
        width: 25px;
        font-size: 16px;
    }

    input[type="checkbox"] {
        height: 25px;
        width: 25px;
      }
  }
