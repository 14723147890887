.cont_links {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;

    position: relative;
    background: #18191f;
    color: #555;
    justify-content: center;
    align-items: center;

    padding: 0;
    margin: 0; 
    font-family: 'Poppins', sans-serif;
}
    
.icon {
    filter: invert(40%) sepia(16%) saturate(127%) hue-rotate(288deg) brightness(85%) contrast(100%);
    width: 8%;
    padding: 0 1.5%;
    margin: 30px;

    width: 40px;
    height: 40px; 
    margin-left: 10px;
    margin-right: 10px;
  }
.blue {
  color: rgb(250, 158, 170);
  }
  a:hover, a:active {
    filter: invert(60%) sepia(76%) saturate(219%) hue-rotate(194deg) brightness(97%) contrast(103%);
  }

  .img_links {
    width: 40px;
    height: 40px; 
    margin-left: 10px;
    margin-right: 10px;
  }

  @media screen and (max-width: 616px) {
    .icon {
      width: 20px;
      height: 20px; 
      margin-left: 5px;
      margin-right: 5px;
    }
  }